import { Action } from 'redux';


export const AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER = 'AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER';

export interface AdSlotAnchorLeaderboardCloseByUserAction extends Action {
    readonly type: typeof AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER;
    readonly closed: boolean;
}

export const adSlotAnchorLeaderboardCloseByUser = (closed: boolean): AdSlotAnchorLeaderboardCloseByUserAction => ({
    type: AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER,
    closed,
});

