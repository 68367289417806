import {
    AdSlotRefreshAction,
    AdSlotRenderEndedAction,
    AD_SLOT_REFRESH,
    AD_SLOT_RENDER_ENDED,
} from 'mk2/containers/AdSlot/AdSlot.actions';
import { PREFIX_AD_SLOT_ANCHOR_LEADERBOARD } from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard';
import {
    AdSlotAnchorLeaderboardCloseByUserAction,
    AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER,
} from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard.actions';

export interface AdSlotAnchorLeaderboardState {
    adSlotRenderEnded: boolean;
    adSlotClosedByUser: boolean;
}

export const initialAdSlotAnchorLeaderboardState: AdSlotAnchorLeaderboardState = {
    adSlotRenderEnded: false,
    adSlotClosedByUser: false,
};

type AdSlotActions = AdSlotRenderEndedAction | AdSlotRefreshAction | AdSlotAnchorLeaderboardCloseByUserAction;

export const adSlotAnchorLeaderboardReducer = (state: AdSlotAnchorLeaderboardState = initialAdSlotAnchorLeaderboardState, action: AdSlotActions) => {
    switch (action.type) {
        case AD_SLOT_REFRESH:
            // We handle only anchor leaderboard ads
            if (!action.slotId.startsWith(PREFIX_AD_SLOT_ANCHOR_LEADERBOARD)) {
                return state;
            }

            return {
                ...state,
                // this slot has been scheduled for refresh - hide slot
                adSlotRenderEnded: false,
            };
        case AD_SLOT_RENDER_ENDED:
            // We handle only anchor leaderboard ads
            if (!action.slotId.startsWith(PREFIX_AD_SLOT_ANCHOR_LEADERBOARD)) {
                return state;
            }

            return {
                ...state,
                adSlotRenderEnded: !action.event.isEmpty,
                adSlotClosedByUser: false,
            };
        case AD_SLOT_ANCHOR_LEADERBOARD_CLOSE_BY_USER:
            return {
                ...state,
                adSlotClosedByUser: action.closed,
            };
        default:
            return state;
    }
};
