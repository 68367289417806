import { Location } from 'history';
import {
    getAdSlotAnchorLeaderboardState,
} from 'mk2/containers/AdSlotAnchorLeaderboard/AdSlotAnchorLeaderboard.selectors';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { AnchorLeaderboardProps } from 'mk2/pages/Page';
import { AppState } from 'mk2/reducers';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import { connect } from 'react-redux';
import styles from './AdSlotAnchorLeaderboard.mscss';

export interface OwnProps extends AnchorLeaderboardProps {
    requestUser: UserEntity;
    location: Location;
}


interface StateProps {
    adSlotClosedByUser: boolean;
}

interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

const AdSlotAnchorLeaderboardPlaceholder: React.FC<Props> = ({
    adSlotClosedByUser,
}) => {
    if (adSlotClosedByUser) {
        return null;
    }

    return (
        <div className={styles.AdSlotAnchorLeaderboardPlaceholder} />
    );
};

function mapStateToProps(state: AppState, _ownProps: OwnProps): StateProps {
    const substate = getAdSlotAnchorLeaderboardState(state);
    return {
        adSlotClosedByUser: substate.adSlotClosedByUser,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
};

export default connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
)(AdSlotAnchorLeaderboardPlaceholder);

